.modal_container{
    justify-content: center;
    align-items: center;
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;
    inset: 0;
    z-index: 10001;
    outline: none;
    background-color: rgb(38,38,38,0.5);
    /* top: 45%;
    right: 50%; */

}

.modal_main{
    position: absolute;
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
}

.modal_show{
    transform: translateY(100%);
    height: 100%;
    opacity: 1;
}

.modal_hide{
    transform: translateY(0px);
    opacity: 0;
    height: 100%;
}

.modal_content{
    background-color: aliceblue;
    height: 90vh;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    position: static;
    display: flex;
    flex-direction: column;
    outline: none;
    width: 100%;
    border: 0;
    margin-bottom: 1em;
}

.modal_header{
    display: flex;
    align-items: center;
    padding: 1.5rem; /* 24px */
    justify-content: center;
    border-top-left-radius: 0.25rem; /* 4px */
    border-top-right-radius: 0.25rem; /* 4px */
    position: sticky;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.modal_title{
     font-size: 1.5rem; /* 18px */
     /*line-height: 1.75rem;  28px */
     font-weight: 600;
}


.modal_close_button{
    position: absolute;
    right: 0%;
    margin-right: 2em; /* 36px */
    border: none;
    background-color: transparent;
}

.close_button{
    color: #E9E8E6;
    border: none;
}

.modal_body{
    flex: 1 1 auto;
    padding: 1.5rem; /* 24px */
    overflow-y: auto;
}

.select_option_group{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
}

.select_option{
    display: flex;
    padding: 2em;
    align-items: center;
}

.select_option_label{
    font-size: small;
    margin-right: 0.5em;
}

.select_dropdown{
    background-color: rgb(204, 204, 204);
    padding: 0.8rem 4rem 0.8rem 1rem;
    border-radius: 0.5em;
    width: 15rem;
    border: 0;
    outline: 0;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
}

.location_submit_button{
    margin: 1em;
    padding: 0.5em 1.2em 0.5em 1.2em;
    border-radius: 1em;
    border : 0;
    background-color: #498467;
    color: aliceblue;
}

.input_box{
    
}