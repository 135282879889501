.tab-wrap {
    transition: 0.3s box-shadow ease;
    border-radius: 6px;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    list-style: none;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .tab-wrap:hover {
    box-shadow: 0 12px 23px rgba(0, 0, 0, 0.23), 0 10px 10px rgba(0, 0, 0, 0.19);
  }
  .tab {
    display: none;
  }
  .tab:checked:nth-of-type(1) ~ .tab__content:nth-of-type(1) {
    opacity: 1;
    transition: 0.5s opacity ease-in, 0.8s transform ease;
    position: relative;
    top: 0;
    z-index: 1000;
    transform: translateY(0px);
    text-shadow: 0 0 0;
  }
  .tab:checked:nth-of-type(2) ~ .tab__content:nth-of-type(2) {
    opacity: 1;
    transition: 0.5s opacity ease-in, 0.8s transform ease;
    position: relative;
    top: 0;
    z-index: 100;
    transform: translateY(0px);
    text-shadow: 0 0 0;
  }
  .tab:checked:nth-of-type(3) ~ .tab__content:nth-of-type(3) {
    opacity: 1;
    transition: 0.5s opacity ease-in, 0.8s transform ease;
    position: relative;
    top: 0;
    z-index: 1000;
    transform: translateY(0px);
    text-shadow: 0 0 0;
  }
  .tab:checked:nth-of-type(4) ~ .tab__content:nth-of-type(4) {
    opacity: 1;
    transition: 0.5s opacity ease-in, 0.8s transform ease;
    position: relative;
    top: 0;
    z-index: 1000;
    transform: translateY(0px);
    text-shadow: 0 0 0;
  }
  .tab:checked:nth-of-type(5) ~ .tab__content:nth-of-type(5) {
    opacity: 1;
    transition: 0.5s opacity ease-in, 0.8s transform ease;
    position: relative;
    top: 0;
    z-index: 1000;
    transform: translateY(0px);
    text-shadow: 0 0 0;
  }
  .tab:first-of-type:not(:last-of-type) + label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .tab:not(:first-of-type):not(:last-of-type) + label {
    border-radius: 0;
  }
  .tab:last-of-type:not(:first-of-type) + label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .tab:checked + label {
    background-color: #fff;
    box-shadow: 0 -1px 0 #fff inset;
    cursor: default;
  }
  .tab:checked + label:hover {
    box-shadow: 0 -1px 0 #fff inset;
    background-color: #fff;
  }
  .tab + label {
    box-shadow: 0 -1px 0 #eee inset;
    border-radius: 6px 6px 0 0;
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: #333;
    flex-grow: 3;
    text-align: center;
    background-color: #e0dddd;
    user-select: none;
    text-align: center;
    transition: 0.3s background-color ease, 0.3s box-shadow ease;
    height: 4.5em;
    box-sizing: border-box;
    padding: 15px;
    font-size: 1.2em;
    font-weight: 600;
  }
  .tab + label:hover {
    background-color: #f9f9f9;
    box-shadow: 0 1px 0 #f4f4f4 inset;
  }
  .tab__content {
    padding: 10px 25px;
    background-color: transparent;
    position: absolute;
    width: 100%;
    z-index: -1;
    opacity: 0;
    left: 0;
    transform: translateY(-3px);
    border-radius: 6px;
  }

  .closeButtonContainerStyle{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    margin-bottom: 5px;
  }

  .closeButtonStyle{
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 0.5em;
    font-weight: 600;
    background-color: #D6D5C9;
    color: #592941;
    cursor: pointer;
    border-width: 1px;
  }

  /* boring stuff */
  .container {
    display: inline-block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 85vw;
    height: 75vh;
    margin: auto;
    background-color: 'rgba(0, 0, 0, 0.5)';;
    backdrop-filter: 'blur(5px)';
    z-Index: '1000',
  }

  h1, h2 {
    margin: 0;
    color: #444;
    text-align: center;
    font-weight: 400;
  }
  h2 {
    font-size: 1em;
    margin-bottom: 30px;
  }
  h3 {
    font-weight: 400;
  }
  p {
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .plan_metadata_main{
    display: flex;
    align-items: center;
  }

  .plan_metadata_key{
    padding: 0.5em;
    font-size: 1.4em;
    font-weight: 500;
  }

  .plan_metadata_data{
    padding: 0.5em;
    font-size: 1.3em;
    color: #444;
  }
  