.header{
    padding: 0.5em;
}

.modal_title {
    font-weight: 700;
    font-size: large;
}

.body{
    margin: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.body_header{
    font-size: medium;
    font-weight: 600;
}

.body_rule{
    width: 50%;
    height: 0.1em;
    background-color: darkgrey;
    border-radius: 5em;
}

.body_content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
.layer_buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-items: center;
}

.layer_button_label {
    text-align: center;
}